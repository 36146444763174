//
// _pagination.scss
//

// Pagination rounded (Custom)
.pagination-rounded {
  .page-link {
    border-radius: 30px !important;
    margin: 0 3px;
    border: none;
    text-align: center;
    height: 35px;
  }
}

.page-link {
  border: 1px none;
  color: #666;
  margin: 0 6px;
}

.pagination {
  margin-bottom: 0;
}

.page-link:hover {
  color: #666;
  border-radius: 0.25rem;
}

.page-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  gap: 10px;
  border-radius: 2px;

  width: 32px;
  height: 32px;
  margin: 0 0 0 12px;

  &:first-child{
    margin: 0;
  }
}

.page-item[title^="first"],
.page-item[title^="last"] {
  display: none;
}

.page-item.disabled .page-link {
  color: #C9CDD4;
  background-color: initial;
  border-color: initial;
}

.page-item.active {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  gap: 10px;

  width: 32px;
  height: 32px;
  margin: 0 0 0 12px;

  /* Foundation /Blue/Light */

  background: #E8EAF1;
  border-radius: 4px;
}

.page-item .page-link {
  width: 24px;
  height: 24px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  padding: 0;
  margin: 0;
  background-color: initial;
  /* or 157% */

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  /* 666 */

  color: #666666;
}

.page-item.active .page-link {
  width: 24px;
  height: 24px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  /* or 157% */

  display: flex;
  align-items: center;
  text-align: center;

  /* 01 */

  color: #0C1C56;
  background: #E8EAF1;
  padding: 0;
  margin: 0;
  /* or 157% */

  justify-content: center;

  &:first-child {
    margin-left: 0;
  }

  &:focus {
    box-shadow: none;
  }
}

.page-item .page-link[title^="previous"],
.page-link[title^="next"] {
  &:focus {
    box-shadow: none;
  }
}

.page-item[title^="next"].disabled .page-link {
  &:focus {
    box-shadow: none;
  }
}

.page-item[title^="previous"].disabled .page-link {
  &:focus {
    box-shadow: none;
  }
}

.total-items {
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #666666;
  }
}
