//
// Select 2
//

.select2-container {
    display: block;
    .select2-selection--single {
        background-color: $input-bg;
        border: 1px solid $input-border-color;
        height: 38px;
        &:focus {
            outline: none;
        }

        .select2-selection__rendered {
            line-height: 36px;
            padding-left: 12px;
            color: $input-color;
        }

        .select2-selection__arrow {
            height: 34px;
            width: 34px;
            right: 3px;

            b {
                border-color: $gray-500 transparent transparent transparent;
                border-width: 6px 6px 0 6px;
            }
        }

        .select2-selection__placeholder {
            color: $body-color;
        }
    }

    .select2-selection--multiple {
        min-height: 38px;
        background-color: $input-bg;
        border: 1px solid $input-border-color !important;

        .select2-selection__rendered {
            padding: 2px 10px;
        }
        .select2-search__field {
            border: 0;
            color: $input-color;
            &::placeholder {
                color: $input-color;
            }
        }
        .select2-selection__choice {
            background-color: $gray-200;
            border: 1px solid $gray-300;
            border-radius: 1px;
            padding: 0 7px;
        }
    }
}

.select2-container--open {
    .select2-selection--single {
        .select2-selection__arrow {
            b {
                border-color: transparent transparent $gray-500 transparent !important;
                border-width: 0 6px 6px 6px !important;
            }
        }
    }
}

.select2-container--default {
    .select2-search--dropdown {
        padding: 10px;
        background-color: $dropdown-bg;
        .select2-search__field {
            border: 1px solid $input-border-color;
            background-color: $input-bg;
            color: $gray-600;
            outline: none;
        }
    }
    .select2-results__option--highlighted[aria-selected] {
        background-color: $primary;
    }
    .select2-results__option[aria-selected="true"] {
        background-color: $dropdown-link-active-bg;
        color: $dropdown-link-active-color;
        &:hover {
            background-color: $primary;
            color: $white;
        }
    }
    &.select2-container--focus {
        .select2-selection--multiple {
            border-color: $gray-400;
        }
    }

    .select2-results__group {
        font-weight: $font-weight-semi-bold;
    }
}

.select2-results__option {
    padding: 6px 12px;
}

.select2-dropdown {
    border: 1px solid $dropdown-border-color;
    background-color: $dropdown-bg;
    box-shadow: $box-shadow;
}

.select2-search {
    input {
        border: 1px solid $gray-300;
    }
}

// ajax select

.select2-result-repository__avatar {
    float: left;
    width: 60px;
    margin-right: 10px;
    img {
        width: 100%;
        height: auto;
        border-radius: 2px;
    }
}

.select2-result-repository__statistics {
    margin-top: 7px;
}

.select2-result-repository__forks,
.select2-result-repository__stargazers,
.select2-result-repository__watchers {
    display: inline-block;
    font-size: 11px;
    margin-right: 1em;
    color: $gray-500;

    .fa {
        margin-right: 4px;

        &.fa-flash {
            &::before {
                content: "\f0e7";
                font-family: "Font Awesome 5 Free", sans-serif;
            }
        }
    }
}

.select2-results__option--highlighted {
    .select2-result-repository__forks,
    .select2-result-repository__stargazers,
    .select2-result-repository__watchers {
        color: rgba($white, 0.8);
    }
}

.select2-result-repository__meta {
    overflow: hidden;
}

// templating-select

.img-flag {
    margin-right: 7px;
    height: 15px;
    width: 18px;
}
.select2-selection {
    &__control {
        border: 1px solid #d0d3d9 !important;
        border-radius: 5px !important;
        &:hover {
            border-color: $color-2 !important;
        }
    }
    &__control--is-focused {
        border: 1px solid $color-2 !important;
        border-color: $color-2 !important;
        box-shadow: 0 0 10px rgba(220, 164, 71, 0.3) !important;
        &:hover {
            border-color: $color-2 !important;
        }
    }
    &__value-container {
        padding: 12px 0 12px 12px !important;
    }

    &__menu {
        z-index: 3 !important;
        margin-top: 1px !important;
        margin-bottom: 0 !important;
        box-shadow: 0 0 19px rgba(0, 0, 0, 0.11) !important;
        border-radius: 0 0 10px 10px !important;
    }

    &__menu-list {
        padding: 20px 0 !important;
    }
    &__option {
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 16px !important;

        color: #373737 !important;
        padding: 6px 12px !important;
    }

    &__option--is-selected {
        background-color: #ffffff !important;
        color: $color-2 !important;
    }
    &__option--is-focused {
        background-color: #ffffff !important;
        color: #b08339 !important;
    }
    &__single-value {
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 16px !important;

        color: #51607d !important;
    }

    &__input-container {
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 16px !important;
        margin: 0 !important;
        padding-bottom: 0 !important;
        padding-top: 0 !important;
        padding-right: 6px !important;
    }
    &__placeholder {
        color: #a9afbb !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 16px !important;

        opacity: 0 !important;
        transition: all 0.3s !important;
    }

    &__indicators {
        padding: 12px 12px 12px 0 !important;
    }

    &__indicator-separator {
        width: 0 !important;
    }
    &__dropdown-indicator {
        padding: 4px 6px !important;
        color: #51607d !important;
    }

    &__clear-indicator {
        padding: 0 !important;

        svg {
            width: 16px !important;
            height: 16px !important;
        }
    }
}

.select-container {
    width: 100%;
    position: relative;
    .custom-react-select {
        padding: 0;
        width: 100%;
        min-height: 40px;
        caret-color: $color-2;
        background: #ffffff;
        border-radius: 5px;
        font-size: 18px;
        outline: none;
        transition: all 0.3s;
        color: #51607d;

        &--error {
            .select2-selection {
                &__control {
                    border-color: #dc3545 !important;

                    &:hover {
                        border-color: $color-2 !important;
                    }

                    &:focus {
                        box-shadow: 0 0 4px rgb(220 53 69 / 25%) !important;
                    }
                }
                &__control--is-focused {
                    border-color: #dc3545 !important;
                    box-shadow: 0 0 4px rgb(220 53 69 / 25%) !important;
                }
            }
        }
    }

    &:has(.select2-selection__control--is-focused) {
        .select2-selection__placeholder {
            color: #a9afbb !important;
            opacity: 1 !important;
            animation-delay: 0.2s !important;
        }

        .label,
        .filled {
            top: -8px;
            color: $color-2;
            font-weight: 400;
            font-size: 12px;
            line-height: 150%;
        }
    }

    &:has(.select2-selection__single-value) {
        .label,
        .filled {
            top: -8px;
            color: $color-2;
            font-weight: 400;
            font-size: 12px;
            line-height: 150%;
        }
    }

    .select-search {
        .select2-selection {
            &__control {
                min-height: 42px;
            }
            &__value-container {
                padding: 3px 0 8px 12px !important;
            }
            &__indicators {
                padding: 0px 12px 6px 0px !important;
            }
        }
    }

    .btn-add-title-level {
        position: absolute;
        right: 0;
        top: 9px;
        cursor: pointer;
        z-index: 1;
        margin-right: 12px;
        & + .custom-react-select {
            .select2-selection {
                &__indicators {
                    padding-right: 0 !important;
                    margin-right: calc(16px + 12px);
                }
            }
        }
    }
}

.select2-selection-lh-24 {
    .select2-selection__value-container {
        padding: 8px 0 8px 8px !important;
    }
    .select2-selection__single-value {
        line-height: 24px !important;
    }
}
