//
// _card.scss
//

.card {
    margin-bottom: $grid-gutter-width;
    box-shadow: $box-shadow;
    border: 0;
}

.card-drop {
    font-size: 20px;
    line-height: 0;
    color: inherit;
}

.card-title {
    font-size: 30px;
    line-height: 24px;
    color: $color-1;
    font-weight: $font-weight-semi-bold;
}

.card-title-desc {
    color: $card-title-desc;
    margin-bottom: 24px;
}
.card-body {
    padding: 20px;
}
