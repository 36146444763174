.date-picker-container {
    position: relative;
    width: 100%;
    display: flex;

    .flatpickr-input {
        width: 100%;
        background-color: #ffffff;
        border: 1px solid #d0d3d9;
        border-radius: 5px;
        padding: 12px 52px 12px 12px;

        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        caret-color: $primary;

        color: #51607d;
        outline: 0;

        &-placeholder-show {
            &::placeholder {
                color: #a9afbb;
                opacity: 1 !important;
                animation-delay: 0.2s;
            }
        }

        &::placeholder {
            opacity: 0;
            color: #999999;
        }

        &:hover {
            border-color: $primary;
        }

        &:active {
            border-color: $primary;
            box-shadow: 0px 0px 10px rgba(220, 164, 71, 0.3);
            &::placeholder {
                color: #a9afbb;
                opacity: 1;
                animation-delay: 0.2s;
            }
        }
        &:focus {
            border-color: $primary;
            box-shadow: 0px 0px 10px rgba(220, 164, 71, 0.3);
            &::placeholder {
                color: #a9afbb;
                opacity: 1;
                animation-delay: 0.2s;
            }
        }
    }
    .flat-pickr-icon-calendar {
        position: absolute;
        right: 17px;
        top: 50%;
        transform: translateY(-50%);
        color: #51607d;

        cursor: pointer;
    }
    .flat-pickr-icon-clear {
        position: absolute;
        right: 42px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 11px;
        color: hsl(0, 0%, 80%);

        opacity: 0;

        cursor: pointer;

        &:hover {
            color: hsl(0, 0%, 40%);
        }

        &-valuable {
            opacity: 1 !important;
        }

        &-active {
            color: hsl(0, 0%, 40%);
        }
    }
}
