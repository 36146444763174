// Easily make an element as wide or as tall (relative to its parent) with our width and height utilities.
.w-5 {
    width: 5%;
}

.w-10 {
    width: 10%;
}

.w-15 {
    width: 15%;
}

.w-20 {
    width: 29%;
}