@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    margin: auto;
    width: 30px;
    height: 30px;
    border: 2px solid #f3f3f3; /* Light grey */
    border-top: 2px solid #383636; /* Blue */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }

  .spinner-sub-container {
    position: absolute;
    top: 60%;
    width: 100%;
    height: 100%;
  }
  
  .spinner-container {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: #e3e3e359;
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner-custom {
    margin: auto;
    width: 50px;
    height: 50px;
    border: 2px solid #f3f3f3; /* Light grey */
    border-top: 2px solid #383636; /* Blue */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }

  .spinner-sub-container-custom {
    position: absolute;
    top: 50%;
    width: 100%;
    height: 100%;
  }
  
  .spinner-container-custom {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: #e3e3e359;
  }