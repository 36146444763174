//
// _card.scss
//

.filter {
    padding: 0 12px 12px;
    background: #FBFAFD;
    border-radius: 10px;
}

.icon-toggle {
    line-height: $line-height-lg;
    height: 22px;
    color: $color-text-1;
    margin-top: 10px;
    margin-right: -2px;
    cursor: pointer;
    font-size: $font-size-xxxxl;
}
