// Typography button
%typography-btn-sm {
    font-weight: $font-weight-normal; // 400
    font-size: $font-size-md; // 14px
    line-height: $line-height-lg; // 21px
}

%typography-btn-md {
    font-weight: $font-weight-normal; // 400
    font-size: $font-size-md; // 14px
    line-height: $line-height-sm; // 16px
}

%typography-btn-lg {
    font-weight: $font-weight-semi-bold; // 600
    font-size: $font-size-md; // 14px
    line-height: $line-height-sm; // 16px
}

%typography-btn-xl {
    font-weight: $font-weight-semi-bold; // 600
    font-size: $font-size-md; // 14px
    line-height: $line-height-sm; // 16px
}

%typography-btn-xxl {
    font-weight: $font-weight-medium; // 500
    font-size: $font-size-xxl; // 20px
    line-height: $line-height-xl; // 23px
}
