.password-reset-init {
    &-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 30px;

        color: #373737;

        margin-top: 20px;
        margin-bottom: 0;
    }
    &-description {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-align: center;

        color: #373737;

        margin-top: 20px;
        margin-bottom: 0;
        padding: 0 20.5px;
    }
}

@media screen and (max-width: 576px) {
    .password-reset-init {
        &-description {
            padding: 0 10px;
        }
    }
}
