//
// _table.scss
//

.table-responsive {
  border-radius: 10px;
  height: 80vh;
}

.table-responsive-without-viewheight {
  border-radius: 10px;
}

.table-editable {
    overflow: inherit;
    tbody {
        tr {
            td {
                overflow: inherit;
            }
        }
    }
}

.title-table {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 30px;
    gap: 12px;

    .title-container {
        flex: 1;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 12px;

        .select-container {
            max-width: 300px;
        }
    }

    .btn-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 10px;
    }

    h2 {
        color: #162B75;
        font-size: 30px;
        font-weight: 600;
        margin-right: 8px;
    }
}

.table {
  th {
    font-weight: $font-weight-semi-bold;
  }


}


//Table centered
.table-centered {
  td, th {
    vertical-align: middle !important;
  }
}

.table-nowrap {
  th, td {
    white-space: nowrap;
  }
}


.table-bordered > :not(caption) > * > * {
  border-width: 0;
}

.table-bordered {
  border-collapse: separate;
  border-spacing: 0;
  table-layout: unset !important;

  th {
    --bs-table-accent-bg: #E6EBFF !important;
    color: #162B75;
    line-height: 16px;
    font-size: 14px;
    font-weight: 600;
    padding: 15px 12px;
  }

  td {
    font-size: 14px;
    font-weight: 400;
    color: $color-text-1;
    padding: 9px 12px;

  }

  th,
  td {
    border-style: solid;
    border-color: $table-border-color;
    border-width: 1px 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  tr th:first-child {
    border-top-left-radius: 10px;
    border-left: $table-border-width solid $table-border-color;
  }

  /* top-right border-radius */
  tr th:last-child {
    border-top-right-radius: 10px;
    border-right: $table-border-width solid $table-border-color;
  }

  tr td:first-child {
    border-left: $table-border-width solid $table-border-color;

  }

  /* bottom-right border-radius */
  tr td:last-child {
    border-right: $table-border-width solid $table-border-color;
  }

  /* bottom-left border-radius */
  tr:last-child td:first-child {
    border-bottom-left-radius: 10px;

  }

  /* bottom-right border-radius */
  tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
  }
}


.table-dark > :not(:last-child) > :last-child > * {
  border-bottom-color: $table-dark-border-color;
}

.table > :not(:first-child) {
  border-top: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: #fff !important;
}

.table-striped > tbody > tr:hover > * {
  --bs-table-accent-bg: #F5F5F5 !important;
}

.table-bordered > :not(caption) > * {
  --bs-table-accent-bg: #F8F8F8;
}

.row-span-p {
  margin: 0;
  border-bottom: 1px solid $border-color;
  padding: 6px 0;
  min-height: 33px;
  white-space: normal;
  word-break: break-word;
}
.table-bordered td .row-span-p:last-child {
  border: 0;
}

.width-10 {
    width: 10%;
}

.width-20 {
    width: 20%;
}

.width-30 {
    width: 30%;
}

.white-space-nowrap {
    white-space: nowrap;
}

.react-bootstrap-table {
    position: relative;
    overflow: auto;
    &:has(.react-bootstrap-table-editing-cell) {
        overflow: inherit !important;
    }

    .react-bootstrap-table-editing-cell {
        overflow: inherit !important;
    }

    th {
        position: sticky;
        top: 0;
        z-index: 2;
        .order-4 {
            &::before {
                content: none !important;
            }
            &::after {
                content: none !important;
            }
        }

        .caret-4-desc {
            &::before {
                content: none !important;
            }
            &::after {
                font-family: "Font Awesome 5 Free", sans-serif;
                content: "\f0d7" !important; // sort down
                line-height: 16px;
                font-size: 14px;
                font-weight: 600;
                color: #162B75;
                margin-left: 9px !important;
            }

        }

        .caret-4-asc {
            &::before {
                font-family: "Font Awesome 5 Free", sans-serif;
                content: "\f0d8" !important; // sort up
                line-height: 16px;
                font-size: 14px;
                font-weight: 600;
                color: #162B75;
                margin-left: 9px !important;
            }
            &::after {
                content: none !important;
            }
        }
    }

    .freeze-panes-last-column {
        position: sticky;
        position: -webkit-sticky;
        right: 0;
        z-index: 1;
    }
}
