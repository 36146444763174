.modal {
    .modal-dialog {
        .modal-content {
            border-radius: 20px;
            .modal-header {
                padding: 32px 28px;
                border-bottom: unset;
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
                .modal-title {
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 21px;
                    color: #373737;
                }
                .btn-close {
                    font-style: normal;
                    font-weight: 300;
                    font-size: 12px;
                    line-height: 14px;
                    color: #666666;
                }
            }
            .modal-body {
                padding: 8px 28px 32px;
            }
        }
    }
}
.modal-backdrop {
    background: rgba(0, 0, 0, 0.7);
    &.show {
        opacity: 1;
    }
}

.title-level-modal {
    max-width: 510px !important;
}
