.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #DCA447;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(220 164 71 / 30%);
}

.form-control {
  min-height: 40px;
}

