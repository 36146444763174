//
// Forms.scss
//

// checkbox input right

.form-check-right {
    padding-left: 0;
    display: inline-block;
    padding-right: $form-check-padding-start;

    .form-check-input {
        float: right;
        margin-left: 0;
        margin-right: $form-check-padding-start * -1;
    }

    .form-check-label {
        display: block;
    }
}

.form-check {
    position: relative;
    text-align: left; /*rtl: right*/
    padding-left: 0.8em;
    .form-check-label-login {
        padding-left: 0.8em;
        cursor: pointer;
        margin-bottom: 0;
        color: #767676;
    }
}

.form-check-label {
    cursor: pointer;
    margin-bottom: 0;
}

.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-select {
    outline: 0;
    box-shadow: none;

    ~ label {
        opacity: 1;
        transform: scale(0.85) translateY(-0.8rem) translateX(0.5rem);
        padding: 0 5px;
        background-color: #fff;
    }
}

.form-floating > label {
    height: auto;
    background-color: transparent;
}

/* Temporary workaround for availity-reactstrap-validation */
.invalid-feedback {
    display: block;
    margin-top: 4px;
    padding: 0 12px;
    margin-bottom: calc(9px + 25px);
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    background: #ffffff;
}
.was-validated .form-control:invalid,
.form-control.is-invalid {
    border-color: #dc3545 !important;
    background-image: none;
    &:focus {
        border-color: #dc3545 !important;
        box-shadow: 0px 0px 4px rgb(220 53 69 / 25%) !important;
        & + .label,
        .filled {
            color: $color-2 !important;
        }
    }
    &:not([value=""]):not(:focus) + .label,
    .filled {
        color: #dc3545 !important;
    }
}

.input-container {
    width: 100%;
    position: relative;
    &:hover {
        .text-input {
            border-color: $color-2 !important;
        }
    }
    .text-input {
        padding: 12px;
        width: 100%;
        min-height: 40px;
        caret-color: $color-2;
        border: 1px solid #d0d3d9;
        background: #ffffff;
        border-radius: 5px;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        outline: none;
        transition: all 0.3s;
        color: #51607d;
        &:focus {
            border: 1px solid $color-2;
            box-shadow: 0px 0px 10px rgba(220, 164, 71, 0.3);
            & + .label,
            .filled {
                top: -8px;
                color: $color-2;
                font-weight: 400;
                font-size: 12px;
                line-height: 150%;
            }

            &::placeholder {
                color: #a9afbb;
                opacity: 1;
                animation-delay: 0.2s;
            }
        }

        &::placeholder {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #a9afbb;
            opacity: 0;
            transition: all 0.3s;
        }
        &[value=""]:not(:focus) + .label,
        .filled {
            top: 13px;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #a9afbb;
        }

        &:not([value=""]):not(:focus) + .label,
        .filled {
            top: -8px;
            font-weight: 400;
            font-size: 12px;
            line-height: 150%;
            color: #a9afbb;
        }
    }
}
.label {
    margin-bottom: 0;
    position: absolute;
    left: 8px;
    top: 13px;
    transition: all 0.2s;
    padding: 0 4px;
    z-index: 1;
    color: #a9afbb;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    background: #ffffff;
    &--valuable {
        top: -8px;
        color: #a9afbb;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
    }
}
