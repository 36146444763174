//
// icons.scss
//

@import "icons/materialdesignicons";
@import "icons/fontawesome-all";
@import "icons/dripicons";
@import "icons/remixicon";

// custom icon color,padding font-size bootstrap

.icon-outline-success {
    width: 23px;
    height: 20px;
    padding: 14px 0;
    color: $success;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: $font-size-xl;
}

.icon-outline-danger {
    width: 18px;
    height: 20px;
    padding: 14px 0;
    color: $danger;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: $font-size-xl;
}

.icon-outline-secondary {
    width: 18px;
    height: 20px;
    padding: 9px 0;
    font-weight: $font-weight-bolder;
    color: $secondary;
    font-size: $font-size-xl;
    display: flex;
    align-items: center;
    text-align: center;
}

.icon-outline-primary {
    width: 14px;
    height: 14px;
    padding: 9px 0;
    font-weight: $font-weight-bolder;
    color: $primary;
    font-size: $font-size-md;
    display: flex;
    align-items: center;
    text-align: center;
}

.icon-back {
    position: absolute;
    top: 8px;
    left: 15px;
    cursor: pointer;
    font-size: x-large;
    color: $secondary;
    &:hover, :active {
        color: #000000bd;
    }
}

.icon-check {
    color: $verse-green;
    font-size: 24px;
}
