//Padding-left
.pl-1 {
    padding-left: 0.25rem;
}
.pl-2 {
    padding-left: 0.5rem;
}
.pl-3 {
    padding-left: 1rem;
}
.pl-4 {
    padding-left: 1.5rem;
}
.pl-5 {
    padding-left: 3rem;
}
//Padding-right
.pr-1 {
    padding-right: 0.25rem;
}
.pr-2 {
    padding-right: 0.5rem;
}
.pr-3 {
    padding-right: 1rem;
}
.pr-4 {
    padding-right: 1.5rem;
}
.pr-5 {
    padding-right: 3rem;
}
// exten gap
.gap-12 {
    gap: 12px;
}
// margin exten
.mb-25 {
    margin-bottom: 25px;
}
.mb-20 {
    margin-bottom: 20px;
}