//
// _menu.scss
//

.metismenu {
  margin: 0;

  li {
    display: block;
    width: 100%;
    margin: 10px 0;
  }

  .mm-collapse {
    display: none;

    &:not(.mm-show) {
      display: none;
    }

    &.mm-show {
      display: block;
    }
  }

  .mm-collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition-timing-function: ease;
    transition-duration: 0.35s;
    transition-property: height, visibility;
  }
}

.vertical-menu {
  width: 266px;
  height: fit-content;
  z-index: 1001;
  bottom: 0;
  margin: 0 20px;
  position: fixed;
  top: 35px;
  box-shadow: 0px 4px 39px 9px rgba(81, 69, 159, 0.09);
  border-radius: 10px;
}

.main-content {
  margin-left: 289px;
  overflow: auto;
  height: 100vh;
  position: relative;

  .content {
    padding: 0 15px 10px 15px;
    margin-top: $header-height;
  }
}

#sidebar-menu {
  padding: 10px 0 20px 0;

  .mm-active {
    > .has-arrow {
      &:after {
        transform: rotate(-180deg);
      }
    }
  }

  .open {
    color: $primary;
    background: #FBFAFD;
  }

  .has-arrow {
    &:after {
      content: "\F0140";
      font-family: "Material Design Icons", sans-serif;
      display: block;
      float: right;
      transition: transform 0.2s;
      font-size: 1rem;
    }
  }

  ul {
    li {
      a {
        display: block;
        padding: 12px 16px;
        color: $color-666;
        position: relative;
        font-size: $font-size-md;
        transition: all 0.4s;
        font-family: $font-family-secondary;
        font-weight: 400;

        i {
          display: inline-block;
          min-width: 1.5rem;
          padding-bottom: 0.125em;
          font-size: 1.1rem;
          line-height: 1.40625rem;
          vertical-align: middle;
          color: $secondary;
          transition: all 0.4s;
          opacity: 0.75;
        }

        &:hover {
          color: $sidebar-menu-item-hover-color;

          i {
            color: $sidebar-menu-item-hover-color;
          }
        }
      }

      .badge {
        margin-top: 4px;
      }

      ul.sub-menu {
        padding: 12px 30px 20px;

        li {
          a {
            padding: 12px;
            font-size: $font-size-md;
            color: $sidebar-menu-sub-item-color;

            &:hover,
            &:focus {
              color: $secondary !important;
              background-color: #FCF6ED;
              border-radius: 8px;

              i {
                color: $secondary;
              }
            }
          }

        }
      }
    }
  }

  #account-menu {
    background-color: #FCF6ED;
    margin: 12px 10px;
    border-radius: 10px;
    width: 246px;

    a {
      padding: 12px 19px 12px 12px;
      color: $color-2;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
    }
  }
}

.menu-title {
  padding: 12px 20px !important;
  letter-spacing: 0.05em;
  pointer-events: none;
  cursor: default;
  font-size: $font-size-md;
  text-transform: uppercase;
  color: $sidebar-menu-item-icon-color;
  font-weight: $font-weight-semi-bold;
  font-family: $font-family-secondary;
  opacity: 0.5;
}

.mm-active {
  color: $color-2 !important;

  > a {
    color: $color-2 !important;


  }


  .active {
    color: $color-2 !important;


  }
}

@media (max-width: 992px) {
  .vertical-menu {
    display: none;
  }

  .main-content {
    margin-left: 0 !important;
  }

  body.sidebar-enable {
    .vertical-menu {
      display: block;
    }
  }
}

// Enlarge menu
.vertical-collpsed {
  .main-content {
    margin-left: $sidebar-collapsed-width;
  }

  .navbar-brand-box {
    padding: 0 11px;
    width: $sidebar-collapsed-width !important;
  }

  .logo {
    span.logo-lg {
      display: none;
    }

    span.logo-sm {
      display: block;
    }
  }

  .simplebar-content {
    height: 740px !important;
  }

  // Side menu
  .vertical-menu {
    position: absolute;
    width: $sidebar-collapsed-width !important;
    z-index: 5;

    .simplebar-content-wrapper {
      overflow: visible !important;
    }


    .simplebar-scrollbar {
      display: none !important;
    }

    .simplebar-offset {
      bottom: 0 !important;
    }

    // Sidebar Menu
    #sidebar-menu {
      .menu-title,
      .badge,
      .collapse.in {
        display: none !important;
      }

      .nav.collapse {
        height: inherit !important;
      }

      .has-arrow {
        &:after {
          display: none;
        }
      }

      > ul {
        > li {
          position: relative;
          white-space: nowrap;

          > a {
            padding: 15px 20px;
            min-height: 55px;
            transition: none;

            &:hover,
            &:active,
            &:focus {
              color: $sidebar-menu-item-hover-color;
            }

            i {
              font-size: 20px;
              margin-left: 4px;
              color: $color-2;
            }

            span {
              display: none;
              padding-left: 25px;
            }
          }

          &:hover {
            > a {
              position: relative;
              width: calc(220px + #{$sidebar-collapsed-width});
              color: $primary;
              background-color: darken($sidebar-bg, 4%);
              transition: none;

              i {
                color: $primary;
              }

              span {
                display: inline;
              }
            }

            > ul {
              display: block;
              left: $sidebar-collapsed-width;
              position: absolute;
              width: 220px;
              height: auto !important;
              box-shadow: 3px 5px 12px -4px rgba(18, 19, 21, 0.1);

              ul {
                box-shadow: 3px 5px 12px -4px rgba(18, 19, 21, 0.1);
              }

              a {
                box-shadow: none;
                padding: 8px 20px;
                position: relative;
                width: 190px;
                z-index: 6;
                color: $sidebar-menu-sub-item-color;

                &:hover {
                  color: $sidebar-menu-item-hover-color;
                }
              }
            }
          }
        }

        ul {
          padding: 5px 0;
          z-index: 9999;
          display: none;
          background-color: $sidebar-bg;

          li {
            &:hover {
              > ul {
                display: block;
                left: 190px;
                height: auto !important;
                margin-top: -36px;
                position: absolute;
                width: 190px;
              }
            }

            > a {
              span.pull-right {
                position: absolute;
                right: 20px;
                top: 12px;
                transform: rotate(270deg);
              }
            }
          }

          li.active {
            a {
              color: $gray-100;
            }
          }
        }
      }
    }
  }
}

body {
  .vertical-menu {
    background: $sidebar-bg;
  }

  #sidebar-menu {
    ul {
      li {
        a {
          color: $color-666;

          i {
            color: $sidebar-menu-item-active-color;
          }

          &:hover {
            color: $color-2;

            i {
              color: $color-2;
            }
          }
        }


        ul.sub-menu {
          li {
            a {
              color: $sidebar-menu-sub-item-color;

              &:hover {
                color: $sidebar-menu-item-hover-color;
              }
            }
          }
        }
      }
    }
  }

  // Enlarge menu
  &.vertical-collpsed {
    min-height: 1400px;

    // Side menu
    .vertical-menu {
      // Sidebar Menu
      #sidebar-menu {
        > ul {
          > li {
            &:hover {
              > a {
                background: lighten($sidebar-bg, 2%);
                color: $sidebar-menu-item-hover-color;

                i {
                  color: $sidebar-menu-item-hover-color;
                }
              }

              > ul {
                a {
                  color: $sidebar-menu-sub-item-color;

                  &:hover {
                    color: $sidebar-menu-item-hover-color;
                  }
                }
              }
            }
          }

          ul {
            background-color: lighten($card-bg, 1%);
          }
        }

        ul {
          > li {
            > a {
              &.mm-active {
                color: $color-2 !important;
              }
            }
          }

          li {
            li {
              &.mm-active,
              &.active {
                > a {
                  color: $color-2 !important;
                }
              }

              a {
                &.mm-active,
                &.active {
                  color: $color-2 !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .mm-active {
    color: $color-2 !important;

    > a {
      color: $color-2 !important;

    }


    .active {
      color: $color-2 !important;

    }
  }
}

// Compact Sidebar

body[data-sidebar-size="small"] {
  .navbar-brand-box {
    width: $sidebar-width-sm;

    @media (max-width: 992px) {
      width: auto;
    }
  }

  .vertical-menu {
    width: $sidebar-width-sm;
    text-align: center;

    .has-arrow:after,
    .badge {
      display: none !important;
    }
  }

  .main-content {
    margin-left: $sidebar-width-sm;
  }

  .footer {
    left: $sidebar-width-sm;
    @media (max-width: 991px) {
      left: 0;
    }
  }

  #sidebar-menu {
    ul li {
      &.menu-title {
        background-color: lighten($sidebar-bg, 2%);
      }

      a {
        i {
          display: block;
        }
      }

      ul.sub-menu {
        li {
          a {
            padding-left: 1.5rem;
          }

          ul.sub-menu {
            li {
              a {
                padding-left: 1.5rem;
              }
            }
          }
        }
      }
    }
  }

  &.vertical-collpsed {
    .main-content {
      margin-left: $sidebar-collapsed-width;
    }

    .vertical-menu {
      #sidebar-menu {
        text-align: left;

        > ul {
          > li {
            > a {
              i {
                display: inline-block;
              }
            }
          }
        }
      }
    }

    .footer {
      left: $sidebar-collapsed-width;
    }
  }
}

// Colored Sidebar

body[data-sidebar="colored"] {
  .vertical-menu {
    background: $primary;
  }

  .navbar-brand-box {
    background-color: $primary;

    .logo-dark {
      display: none;
    }

    .logo-light {
      display: block;
    }
  }

  #sidebar-menu {
    ul {
      li {
        &.menu-title {
          color: rgba($white, 0.6);
        }

        a {
          color: rgba($white, 0.6);

          i {
            color: rgba($white, 0.6);
          }

          &.waves-effect {
            .waves-ripple {
              background: rgba($white, 0.1);
            }
          }
        }

        ul.sub-menu {
          li {
            a {
              color: rgba($white, 0.5);
            }
          }
        }
      }
    }
  }
}
