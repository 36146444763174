.mb-25 {
    margin-bottom: 25px;
}
@mixin mb-number($number) {
    .mb-#{$number} {
        margin-bottom: #{$number}px;
    }
}
@include mb-number(40);

@mixin mb-invalid-feedback($line: 1) {
    .mb-invalid-feedback-#{$line}-line {
        margin-bottom: calc(#{($line - 1) * -12}px - 25px);
    }
}

@include mb-invalid-feedback(1);
@include mb-invalid-feedback(2);
@include mb-invalid-feedback(3);

.mb-negative-25 {
    margin-bottom: -25px;
}
.mb-negative-40 {
    margin-bottom: -40px;
}
.mt-40 {
    margin-top: 40px;
}
