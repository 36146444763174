.switch-container {
    display: flex;
    gap: 16px;
    align-items: center;
    .label-switch {
        margin-bottom: 0;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #3d485c;
    }
}
