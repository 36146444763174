// Override Boostrap variables
// Import Bootstrap source files from node_modules
@import "./node_modules/bootstrap/scss/bootstrap";

@import "./assets/styles/themes.scss";
@import "./assets/styles/custom/fonts/fonts";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap");

body {
    background: #fafafa;
    margin: 0;
    font-family: $font-family-secondary !important;
}

a {
    color: #533f03;
    // font-weight: bold;
}

p {
    margin-bottom: 0;
}

* {
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    &:after,
    &::before {
        -moz-box-sizing: border-box;
        box-sizing: border-box;

        &:after,
        &::before {
            -moz-box-sizing: border-box;
            box-sizing: border-box;
        }
    }
}

.app-container {
    box-sizing: border-box;
    background: radial-gradient(123.22% 129.67% at 100.89% -5.6%, #FFFFFF 0%, #FEFEFF 100%);

    .view-container {
        width: 100%;
        height: calc(100% - 40px);
        overflow-y: auto;
        overflow-x: hidden;
        padding: 1rem;

        .card {
            padding: 1rem;
        }

        .view-routes {
            // height: 100%;
            // min-height: calc(100vh - 70px - 24px - 60px - 24px);
            > div {
                height: 100%;
            }
        }
    }
}

.fullscreen {
    position: fixed;
    top: 100px;
    left: 0px;
    width: 99% !important;
    height: calc(100vh - 110px) !important;
    margin: 5px;
    z-index: 1000;
    padding: 5px 25px 50px 25px !important;
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */

.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

/* ==========================================================================
Custom button styles
========================================================================== */

.icon-button > .btn {
    background-color: transparent;
    border-color: transparent;
    padding: 0.5rem;
    line-height: 1rem;

    &:hover {
        background-color: transparent;
        border-color: transparent;
    }

    &:focus {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
}

/* ==========================================================================
Generic styles
========================================================================== */

/* other generic styles */

.title {
    font-size: 1.25em;
    margin: 1px 10px 1px 10px;
}

.description {
    font-size: 0.9em;
    margin: 1px 10px 1px 10px;
}

.shadow {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
    border-radius: 2px;
}

.error {
    color: white;
    background-color: red;
}

.break {
    white-space: normal;
    word-break: break-all;
}

.break-word {
    white-space: normal;
    word-break: keep-all;
}

.preserve-space {
    white-space: pre-wrap;
}

.text-success-green {
    color: green;
}

/* padding helpers */

@mixin pad($size, $side) {
    @if $size== "" {
        @if $side== "" {
            .pad {
                padding: 10px !important;
            }
        } @else {
            .pad {
                padding-#{$side}: 10px !important;
            }
        }
    } @else {
        @if $side== "" {
            .pad-#{$size} {
                padding: #{$size}px !important;
            }
        } @else {
            .pad-#{$side}-#{$size} {
                padding-#{$side}: #{$size}px !important;
            }
        }
    }
}

@include pad("", "");
@include pad("2", "");
@include pad("3", "");
@include pad("5", "");
@include pad("10", "");
@include pad("20", "");
@include pad("25", "");
@include pad("30", "");
@include pad("50", "");
@include pad("75", "");
@include pad("100", "");
@include pad("4", "top");
@include pad("5", "top");
@include pad("10", "top");
@include pad("20", "top");
@include pad("25", "top");
@include pad("30", "top");
@include pad("50", "top");
@include pad("75", "top");
@include pad("100", "top");
@include pad("4", "bottom");
@include pad("5", "bottom");
@include pad("10", "bottom");
@include pad("20", "bottom");
@include pad("25", "bottom");
@include pad("30", "bottom");
@include pad("50", "bottom");
@include pad("75", "bottom");
@include pad("100", "bottom");
@include pad("5", "right");
@include pad("10", "right");
@include pad("20", "right");
@include pad("25", "right");
@include pad("30", "right");
@include pad("50", "right");
@include pad("75", "right");
@include pad("100", "right");
@include pad("5", "left");
@include pad("10", "left");
@include pad("20", "left");
@include pad("25", "left");
@include pad("30", "left");
@include pad("50", "left");
@include pad("75", "left");
@include pad("100", "left");

@mixin no-padding($side) {
    @if $side== "all" {
        .no-padding {
            padding: 0 !important;
        }
    } @else {
        .no-padding-#{$side} {
            padding-#{$side}: 0 !important;
        }
    }
}

@include no-padding("left");
@include no-padding("right");
@include no-padding("top");
@include no-padding("bottom");
@include no-padding("all");

/* end of padding helpers */

.no-margin {
    margin: 0px;
}

@mixin voffset($size) {
    @if $size== "" {
        .voffset {
            margin-top: 2px !important;
        }
    } @else {
        .voffset-#{$size} {
            margin-top: #{$size}px !important;
        }
    }
}

@include voffset("");
@include voffset("5");
@include voffset("10");
@include voffset("15");
@include voffset("30");
@include voffset("40");
@include voffset("60");
@include voffset("80");
@include voffset("100");
@include voffset("150");

.readonly {
    background-color: #eee;
    opacity: 1;
}

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */

a:hover {
    cursor: pointer;
}

.hand {
    cursor: pointer;
}

button.anchor-btn {
    background: none;
    border: none;
    padding: 0;
    align-items: initial;
    text-align: initial;
    width: 100%;
}

a.anchor-btn:hover {
    text-decoration: none;
}

/* ==========================================================================
Metrics and Health styles
========================================================================== */

#threadDump .popover,
#healthCheck .popover {
    top: inherit;
    display: block;
    font-size: 10px;
    max-width: 1024px;
}

.thread-dump-modal-lock {
    max-width: 450px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

#healthCheck .popover {
    margin-left: -50px;
}

.health-details {
    min-width: 400px;
}

/* bootstrap 3 input-group 100% width
http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */

.width-min {
    width: 1% !important;
}

/* jhipster-needle-scss-add-main JHipster will add new css style */

.fw-normal {
    font-weight: 400;
}
.fw-semi-bold {
    font-weight: 600;
}
