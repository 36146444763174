//
// authentication.scss
//

.auth-body-bg {
    background-color: $card-bg;
}

.authentication-page-content {
    height: 100vh;
    display: flex;
}

.form-check {
    padding-left: 1.45rem;
}

// css floating label
.auth-form-group-custom {
    position: relative;
    margin-bottom: 20px;

    .form-control {
        height: 56px;
        border-radius: 0;
        border-style: hidden hidden solid hidden;
        border-bottom: 1px solid #e8e8e9;

        &:focus {
            box-shadow: 0 0.25rem 0 0 rgb(220 164 71 / 30%) !important;
        }
    }

    .form-control.is-invalid:focus {
        box-shadow: 0 0.25rem 0 0 rgb(220 53 69 / 25%) !important;
    }

    .floating-label {
        pointer-events: none;
        position: absolute;
        height: 16px;
        left: 0px;
        top: 20px;
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #999;
        transition: top 200ms ease-in, left 200ms ease-in,
            font-size 200ms ease-in;
    }

    .was-validated .form-control:invalid:focus,
    .form-control.is-invalid:focus~.floating-label {
        top: 0px;
        left: 15px;
        font-size: 12px;
    }

    input:not([value=""]):not(:focus)~.floating-label {
        top: 0px;
        left: 15px;
        font-size: 12px;
    }

    .form-control:focus~.floating-label {
        top: 0px;
        left: 15px;
        font-size: 12px;
    }

    .auti-custom-input-icon {
        position: absolute;
        bottom: calc(100% - 75px);
        transform: translateY(-50%);
        left: 19px;
        font-size: 24px;
        color: $secondary;
    }
}

.pass-form-group {
    position: relative;
    margin-bottom: 20px;

    .form-control {
        border: 1px solid #e8e8e9;
    }

    .floating-label {
        pointer-events: none;
        position: absolute;
        height: 16px;
        left: 18px;
        top: 12.5px;
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #999;
        transition: top 200ms ease-in, left 200ms ease-in,
            font-size 200ms ease-in;
    }

    .was-validated .form-control:invalid:focus,
    .form-control.is-invalid:focus~.floating-label {
        top: -8px;
        left: 15px;
        font-size: 12px;
        background-color: #fff;
    }

    input:not([value=""]):not(:focus)~.floating-label {
        top: -8px;
        left: 15px;
        font-size: 12px;
        background-color: #fff;
    }

    .form-control:focus~.floating-label {
        top: -8px;
        left: 15px;
        font-size: 12px;
        background-color: #fff;
    }

    .auti-custom-input-icon {
        position: absolute;
        bottom: calc(100% - 75px);
        transform: translateY(-50%);
        left: 19px;
        font-size: 24px;
        color: $secondary;
    }
}

// int css form login
.custom-form-login {
    background-image: url("../../../../../content/images/authentication-bg-vh.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
     /* Full height */
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    transition: opacity 0.15s linear;

    .fotter {
        padding-top: 40px;
    }

    .fotter-label {
        display: flex;
        padding-top: 20px;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        align-items: center;
        text-align: center;
        color: #666666;
        flex: none;
        order: 1;
        flex-grow: 0;

        span {
            color: #c4842a;
            padding-left: 3px;
        }
    }
}

.form-content {
    position: relative;
    background-color: $white;
    border-radius: 20px;
    padding: 100px 120px;
    width: 100%;
    pointer-events: auto;
    background-clip: padding-box;
    outline: 0;
    max-height: 670px;
    max-width: 640px;

    &-message-success {
        max-width: 661px;
        text-align: center;
    }

    .logo {
        margin: auto;
        margin-bottom: 68px;
        padding: 0;
        width: 184px;
        height: 95px;
    }

    .logo-setting-pass {
        margin: auto;
        margin-bottom: 28px;
        padding: 0;
        width: 184px;
        height: 95px;
    }

    .title {
        margin-bottom: 40px;

        h4 {
            color: $primary;
            font-weight: $font-weight-semi-bold;
            font-size: $font-size-xxxxl;
        }
    }

    .box-alert {
        border: 0;
        border-radius: 0;
        text-align: center;
        padding: 10px;

        span {
            font-weight: $font-weight-medium;
            color: $danger;
        }
    }

    small {
        color: #999;
    }

    .action-remember {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        /* identical to box height, or 20px */

        display: flex;
        align-items: center;
        text-align: center;

        /* text02 */

        color: #767676;

        /* Inside auto layout */

        flex: none;
        order: 0;
        flex-grow: 0;

        .form-check-input {
            margin-top: 0;
        }
    }

    .action-forgot {
        font-style: normal;
        font-size: 14px;
        font-weight: 500;
        line-height: 140%;
        display: flex;
        align-items: center;
        text-align: right;
        color: #767676;
        flex: none;
        order: 1;
        flex-grow: 0;

        a {
            color: #767676 !important;
            margin-top: 2px;
        }
    }

    .form-body {
        position: relative;
        flex: 1 1 auto;
    }
}

// logo
.auth-logo {
    &.logo-light {
        display: $display-none;
    }

    &.logo-dark {
        display: $display-block;
    }
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/* Change the white to any color */
input:-webkit-autofill~.floating-label,
input:-webkit-autofill:hover~.floating-label,
input:-webkit-autofill:focus~.floating-label,
input:-webkit-autofill:active~.floating-label {
    top: -8px;
    left: 15px;
    font-size: 12px;
    background-color: #fff;
}

// Large devices (desktops, 992px and down)
@media only screen and (max-width: 992px) {
    .form-content{
        padding: 80px 100px;
    }
}

// Medium devices (tablets, 768px and down)
@media only screen and (max-width: 768px) {
    .form-content{
        padding: 60px 80px;
    }
}

// Small devices (phones, 576px and down)
@media only screen and (max-width: 576px) {
    .form-content{
        padding: 34px 17px;
    }
}
