//
// _buttons.scss
//

button,
a {
    outline: none !important;
}

.btn {
    // default btn-size-md
    @extend %typography-btn-md;
    padding: 8px 20px;

    white-space: nowrap;

    border-radius: 4px;
    border: 1px solid #ffffff;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;

    &:active:focus {
        box-shadow: none !important;
    }
    &:focus {
        box-shadow: none !important;
    }
}

.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
    color: $primary;
    background-color: $white;
    border-color: $primary;
}

.btn-size {
    &-sm {
        @extend %typography-btn-sm;
        padding: 5.5px 30px;
    }
    &-lg {
        @extend %typography-btn-lg;
        padding: 10px 20px;
        border-radius: 8px;
    }
    &-xl {
        @extend %typography-btn-xl;
        padding: 12px 20px;
        border-radius: 4px;
    } // search btn
    &-xxl {
        @extend %typography-btn-xxl;
        padding: 16px 80px;
        border-radius: 52px;
    }
}

@mixin btn-color ($color-name, $base-text-color, $base-bg-color, $base-border-color, $background-color, $hover-color, $active-bg-color, $active-border-color, $focus-bg-color, $focus-border-color, $disabled-bg-color, $disabled-border-color){
    .btn-#{$color-name} {
        color: $base-text-color;
        background-color: $base-bg-color;
        border-color: $base-border-color;

        &:hover {
            color: $hover-color !important;
            background-color: $background-color !important;
            border-color: $hover-color !important;
        }
        &:active {
            color: $base-text-color !important;
            background-color: $active-bg-color !important;
            border-color: $active-border-color !important;
        }

        &:focus {
            color: $base-text-color;
            background-color: $base-bg-color;
            border-color: $base-border-color;
            box-shadow: none;
        }

        &:disabled{
            color: $base-text-color;
            background-color: $disabled-bg-color;
            border-color: $disabled-border-color;
        }
    }
}

@include btn-color(
    "primary", // color name
    $white, // base text color
    $primary, // base background color
    $primary, // base border color
    $white, // hover background color
    $primary, // hover border color
    $color-btn-primary-active, // active background color
    $color-btn-primary-active, // active border color
    $color-btn-primary-active, // focus background color
    $color-btn-primary-active, // focus border color
    $color-btn-primary-active, // disable background color
    $color-btn-primary-active // disable border color
);

@include btn-color(
    "secondary", // color name
    $white, // base text color
    $secondary, // base background color
    $secondary, // base border color
    $white, // hover background color
    $secondary, // hover border color
    $color-btn-secondary-active, // active background color
    $color-btn-primary-active, // active border color
    $color-btn-secondary-active, // focus background color
    $color-btn-secondary-active, // focus border color
    $color-btn-secondary-active, // disable background color
    $color-btn-secondary-active // disable border color
);

@include btn-color(
    "danger", // color name
    $white, // base text color
    $danger, // base background color
    $danger, // base border color
    $white, // hover background color
    $danger, // hover border color
    $color-btn-danger-active, // active background color
    $color-btn-primary-active, // active border color
    $color-btn-danger-active, // focus background color
    $color-btn-danger-active, // focus border color
    $color-btn-danger-active, // disable background color
    $color-btn-danger-active // disable border color
);

@include btn-color(
    "add", // color name
    #373737, // base text color
    $white, // base background color
    #E8E8E9, // base border color
    $white, // hover background color
    #373737,  // hover border color
    $white, // active background color
    $color-btn-primary-active, // active border color
    $white, // focus background color
    $color-btn-secondary-active, // focus border color
    $white, // disable background color
    $color-btn-secondary-active // disable border color
);

.btn-close {
    color: $color-btn-close !important;
    &:hover{
        color: $color-btn-close-hover !important;
    }
    &:active{
        color: $color-btn-close-active !important;
    }
    &:focus {
        box-shadow: none;
    }
}

@media screen and (max-width: 576px) {
    .btn-size {
        &-xxl {
            padding: 16px 40px;
        }
    }
}

.btn-delete {
    cursor: pointer;
    i {
        color: $danger;
        font-size: 25px;
    }
}

.btn-disabled {
    pointer-events: none;
}

.btn-icon {
    min-width: 42px;
}
